<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
      | {{ snackbar.text }}
      v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
        v-icon mdi-close
    v-container#product-table(fluid='' tag='section')
      v-tabs(vertical='')
        v-tab
          v-tooltip(bottom="")
            template(v-slot:activator="{ on }")
              v-icon(left='' v-on="on")
                | mdi-clipboard-text
            span.tooltips PRODUCTOS - COMBOS
        v-tab
          v-tooltip(bottom="")
            template(v-slot:activator="{ on }")
              v-icon(left='' v-on="on")
                | mdi-clipboard-flow
            span.tooltips TRANSACCIONES
        v-tab-item
          v-row
            v-col(cols="12" md="12")
              h3(style='padding-top:20px;') REPORTE DE INVENTARIO - PRODUCTOS Y COMBOS
            v-col(cols='12' md='4' style='padding: 0px; margin-top: 30px;')
              v-card(style="margin-top: 0px;")
                v-row(style="margin: 5px;")
                  v-col(cols="12")
                    v-select(v-model='selectProductType' return-object :items='productTypes' label='Tipo de Producto')
                  //v-col(cols="12" md="12")
                    v-select(v-model='selectTypeFilter' return-object :items='typeFilter' label='Tipo de listado')
                  v-col(cols="12" md="12")
                    v-menu(ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='dateFrom' transition='scale-transition' offset-y='' min-width='290px')
                      template(v-slot:activator='{ on }')
                        v-text-field(readonly v-model='dateFrom' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
                      v-date-picker(@change='$refs.menuFrom.save(dateFrom)' v-model='dateFrom' no-title='' scrollable='' :max="dateUntil" style='margin:0px;' color="green lighten-1" :min="minDateForConsults")
                  v-col(cols="12" md="12")
                    v-menu(ref='menuUntil' v-model='menuUntil' :close-on-content-click='false' :return-value.sync='dateUntil' transition='scale-transition' offset-y='' min-width='290px')
                      template(v-slot:activator='{ on }')
                        v-text-field(readonly v-model='dateUntil' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
                      v-date-picker(@change='$refs.menuUntil.save(dateUntil)' v-model='dateUntil' no-title='' scrollable='' :min="dateFrom" style='margin:0px;' color="green lighten-1")
                  v-col(cols="12" md="12")
                    v-btn(style="background-color: RGB(1, 125, 71); width: 100%;" @click="filterProductComboData" :loading="loadingGetProductData")
                      | OBTENER DATOS
                      v-icon(style="font-size: 30px; color: white;") mdi-magnify
                  v-col(cols="12" style='padding:2px;')
                    v-btn.mr-0(color='secondary' @click='downLoadExcelFileReport' style="width: 100%;")
                      strong
                        | DESCARGAR LISTADO
            v-col(cols='12' md='8' style='padding: 0px;')
              v-col(cols="12" md="12")
                v-text-field(@keyup="filterProductDataReport" v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
              v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
              v-data-table.mx-2#tablaDatos(v-if="$store.state.profile.profileLoaded" :headers="headersProduct" v-show="!firstLoad" :items="lstProductGroupReport" item-key="uid" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPorProduct" calculate-widths :footer-props="{\'items-per-page-text':'Productos por página'\}")
                template(v-slot:item.totalView="{ item }") $ {{item.total}}
                v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                  | Sin resultados
                v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                  | Sin datos
                v-flex(xs12='', sm10='', offset-sm1='')
        v-tab-item
          v-row
            v-col(cols="12" md="12")
              h3(style='padding-top:20px;') REPORTE DE TRANSACCIONES
            // v-col(cols="4")
              h3(style='padding-top:20px;') CANTIDAD: ${{quantityEnd}}
            // v-col(cols="4")
              h3(style='padding-top:20px;') TOTAL: ${{totalEnd}}
            v-col(cols='12' md='4' style='padding: 0px;')
              v-card(style="margin-top: 0px;")
                v-row(style="margin: 5px;")
                  v-col(cols="12")
                    v-autocomplete(:rules='[rules.required]' @change="" v-model='selectProduct' :items='arrLstProductsCombo' return-object='return-object' item-text='completeData' label='Productos')
                  v-col(cols="12")
                    v-select(v-model='selectType' return-object :items='eventType' label='Tipo de Transacción')
                  v-col(cols="12" md="12")
                    v-menu(ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='dateFrom' transition='scale-transition' offset-y='' min-width='290px')
                      template(v-slot:activator='{ on }')
                        v-text-field(readonly v-model='dateFrom' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
                      v-date-picker(@change='$refs.menuFrom.save(dateFrom)' v-model='dateFrom' no-title='' scrollable='' :max="dateUntil" style='margin:0px;' color="green lighten-1" :min="minDateForConsults")
                  v-col(cols="12" md="12")
                    v-menu(ref='menuUntil' v-model='menuUntil' :close-on-content-click='false' :return-value.sync='dateUntil' transition='scale-transition' offset-y='' min-width='290px')
                      template(v-slot:activator='{ on }')
                        v-text-field(readonly v-model='dateUntil' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
                      v-date-picker(@change='$refs.menuUntil.save(dateUntil)' v-model='dateUntil' no-title='' scrollable='' :min="dateFrom" style='margin:0px;' color="green lighten-1")
                  v-col(cols="6" style='padding:2px;')
                    v-btn(style="background-color: RGB(1, 125, 71); width: 100%;" @click="filterProductData")
                      | OBTENER DATOS
                      v-icon(style="font-size: 30px; color: white;") mdi-magnify
                  v-col(cols="6" style='padding:2px;')
                    v-btn.mr-0(color='secondary' @click='downLoadExcelFile' style="width: 100%;")
                      strong
                        | DESCARGAR LISTADO
            v-col(cols='12' md='8' style='padding: 0px;')
              v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
              v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!firstLoad" :items="lstTransactionsReportFilter" item-key="uid" dense fixed-header :loading="cargando" :sort-desc="arrSortDesc" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Transacciones por página'\}")
                template(v-slot:item.documentIdVal="{ item }")
                  label(style="color: #804100; font-weight: bold;") {{item.documentId}}
                template(v-slot:item.totalView="{ item }")
                  label(style="color: #804100; font-weight: bold;") ${{(parseFloat(item.transactionQuantity)*parseFloat(item.activeValue)).toFixed(3)}}
                //- Mensaje de alerta cuando no hay resultados
                v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
                  | Sin resultados
                v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
                  | Sin datos
                v-flex(xs12='', sm10='', offset-sm1='')
</template>
<script>
  import moment from 'moment'
  import * as functions from '../../../../functions.js'
  export default {
    data: () => ({
      minDateForConsults: moment().subtract(15, 'days').format('YYYY-MM-DD'),
      loadingGetProductData: false,
      typeFilter: [
        {
          code: '001',
          text: 'TODOS',
        },
        {
          code: '002',
          text: 'INVENTARIO POSITIVO',
        },
        {
          code: '003',
          text: 'INVENTARIO NEGATIVO',
        },
      ],
      selectTypeFilter: {
        code: '001',
        text: 'TODOS',
      },
      search: '',
      productsLstFilter: [],
      headersProduct: [
        {
          text: 'Cod. Artículo',
          value: 'producto.codArticle',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Nombre',
          value: 'producto.name',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Tipo',
          value: 'producto.type.texto',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Cant. Vendidos',
          value: 'cantidad',
          align: 'left',
          filterable: true,
        },
        {
          text: 'Valor Total',
          value: 'totalView',
          align: 'left',
          filterable: true,
        },
      ],
      quantityEnd: '0.00',
      totalEnd: '0.00',
      lstTransactionsReportFilter: [],
      ordenarPor: ['transactionDate', 'documentIdVal'],
      ordenarPorProduct: 'codArticle',
      arrSortDesc: [true, true],
      headers: [
        { text: 'Id - Documento', value: 'documentIdVal', align: 'left', filterable: true },
        { text: 'Tipo de transacción', value: 'transactionType', align: 'left', filterable: true },
        { text: 'Descripción', value: 'description', align: 'left', filterable: true },
        { text: 'Fecha de transacción', value: 'transactionDate', align: 'left', filterable: true },
        { text: 'Cantidad', value: 'transactionQuantity', align: 'left', filterable: true },
        { text: 'Total', value: 'totalView', align: 'left', filterable: true },
      ],
      productTypes: [
        {
          code: '001',
          text: 'TODOS',
        },
        {
          code: '002',
          text: 'COMBOS',
        },
        {
          code: '003',
          text: 'PRODUCTOS',
        },
      ],
      eventType: [
        {
          code: '001',
          text: 'TODOS',
        },
        {
          code: '002',
          text: 'SALIDA',
        },
        {
          code: '003',
          text: 'INGRESO',
        },
      ],
      firstLoad: false,
      cargando: false,
      selectProduct: null,
      selectType: {
        code: '001',
        text: 'TODOS',
      },
      selectProductType: {
        code: '001',
        text: 'TODOS',
      },
      arrLstProductsCombo: [],
      rules: {
        required: value => !!value || 'Requerido',
        min: v => v.length >= 6 || 'Mínimo 6 caracteres',
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      menuUntil: false,
      menuFrom: false,
      dateUntil: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    }),
    computed: {
      plan () {
        return this.$store.state.suscriptionPlan.plan
      },
      productLst () {
        return this.$store.state.product.lstProductsActive
      },
      comboLst () {
        return this.$store.state.product.lstCombos
      },
      lstTransactionsReport () {
        return this.$store.state.transaction.lstTransactionsReport
      },
      lstProductGroupReport () {
        return this.$store.state.factura.lstProductGroupReport
      },
    },
    watch: {
      plan () {
        if (this.plan.diasConsulta) {
          this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
        }
      },
      productLst () {
        this.arrLstProductsCombo = this.productLst.concat(this.comboLst)
        this.productsLstFilter = this.productLst.concat(this.comboLst)
        this.selectProduct = this.arrLstProductsCombo[0]
        this.selectType = {
          code: '001',
          text: 'TODOS',
        }
        this.selectProductType = {
          code: '001',
          text: 'TODOS',
        }
        this.$store.dispatch('transaction/loadTransactionsReport', [this.arrLstProductsCombo[0].productKey, this.dateFrom, this.dateUntil, '001'])
      },
      comboLst () {
        this.arrLstProductsCombo = this.productLst.concat(this.comboLst)
        this.productsLstFilter = this.productLst.concat(this.comboLst)
        this.selectProduct = this.arrLstProductsCombo[0]
        this.selectType = {
          code: '001',
          text: 'TODOS',
        }
        this.selectProductType = {
          code: '001',
          text: 'TODOS',
        }
        this.$store.dispatch('transaction/loadTransactionsReport', [this.arrLstProductsCombo[0].productKey, this.dateFrom, this.dateUntil, '001'])
      },
      lstTransactionsReport () {
        this.lstTransactionsReportFilter = this.lstTransactionsReport
      },
    },
    methods: {
      downLoadExcelFileReport () {
        const data = []
        if (this.lstProductGroupReport) {
          for (let i = 0; i < this.lstProductGroupReport.length; i++) {
            data.push({
              'Cod. de articulo': this.lstProductGroupReport[i].producto.codArticle,
              Nombre: this.lstProductGroupReport[i].producto.name,
              Tipo: this.lstProductGroupReport[i].producto.type.texto,
              'Cant. Vendidos': this.lstProductGroupReport[i].cantidad,
              Total: this.lstProductGroupReport[i].total,
            })
          }
          functions.JSONToCSVConvertor(data, 'Productos', true)
        }
      },
      filterProductDataReport () {
        this.productsLstFilter = this.arrLstProductsCombo.filter((item) => {
          return (
            (item.codArticle.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(this.search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase())) ||
            (item.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase().includes(this.search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()))
          )
        })
      },
      getColorQuantity (valor) {
        if (valor.toString().trim() !== '') {
          if (parseFloat(valor) >= 0) {
            return 'color: black !important; font-size: 14px; !important'
          } else {
            return 'color: red !important; font-size: 14px !important; font-weight: bold !important;'
          }
        } else {
          return 'color: black !important; font-size: 14px; !important'
        }
      },
      getColor (valor) {
        if (!valor || valor === 'ACTIVO') {
          return 'green'
        } else {
          return 'red'
        }
      },
      getQuantityTotal () {
        this.quantityEnd = 0
        this.totalEnd = 0
        if (this.lstTransactionsReportFilter) {
          for (let i = 0; i < this.lstTransactionsReportFilter.length; i++) {
            if (this.lstTransactionsReportFilter[i].transactionType.toString().toUpperCase() === 'INGRESO') {
              this.quantityEnd = parseInt(this.quantityEnd) + parseInt(this.lstTransactionsReportFilter[i].transactionQuantity)
            }
            if (this.lstTransactionsReportFilter[i].transactionType.toString().toUpperCase() === 'SALIDA') {
              this.quantityEnd = parseInt(this.quantityEnd) + parseInt(this.lstTransactionsReportFilter[i].transactionQuantity)
            }
          }
        }
      },
      filterProductData () {
        this.$store.dispatch('transaction/loadTransactionsReport', [this.selectProduct.productKey, this.dateFrom, this.dateUntil, this.selectType.code])
      },
      filterProductComboData () {
        this.loadingGetProductData = true
        const data = {
          firstDay: this.dateFrom,
          lastDay: this.dateUntil,
          type: this.selectProductType,
        }
        this.$store.dispatch('factura/loadProductsReportFac', data).then(res => {
          this.loadingGetProductData = false
        })
      },
      downLoadExcelFile () {
        const data = []
        if (this.lstTransactionsReportFilter) {
          for (let i = 0; i < this.lstTransactionsReportFilter.length; i++) {
            data.push({
              Documento: this.lstTransactionsReportFilter[i].documentId,
              Tipo: this.lstTransactionsReportFilter[i].transactionType,
              Descripción: this.lstTransactionsReportFilter[i].description,
              Fecha: this.lstTransactionsReportFilter[i].transactionDate,
              Cantidad: this.lstTransactionsReportFilter[i].transactionQuantity,
              Total: (parseFloat(this.lstTransactionsReportFilter[i].transactionQuantity) * parseFloat(this.lstTransactionsReportFilter[i].activeValue)).toFixed(3),
            })
          }
          functions.JSONToCSVConvertor(data, 'Reporte de Productos', true)
        }
      },
    },
    mounted () {
      if (this.plan.diasConsulta) {
        this.minDateForConsults = moment().subtract(this.plan.diasConsulta, 'days').format('YYYY-MM-DD')
      }
      this.arrLstProductsCombo = this.$store.state.product.lstProductsActive.concat(this.$store.state.product.lstCombos)
      this.productsLstFilter = this.$store.state.product.lstProductsActive.concat(this.$store.state.product.lstCombos)
      this.selectProduct = this.arrLstProductsCombo[0]
      this.selectType = {
        code: '001',
        text: 'TODOS',
      }
      this.selectProductType = {
        code: '001',
        text: 'TODOS',
      }
      this.$store.dispatch('transaction/loadTransactionsReport', [this.arrLstProductsCombo[0].productKey, this.dateFrom, this.dateUntil, '001'])
      this.lstTransactionsReportFilter = this.$store.state.transaction.lstTransactionsReport
      const data = {
        firstDay: this.dateFrom,
        lastDay: this.dateUntil,
        type: this.selectProductType,
      }
      this.$store.dispatch('factura/loadProductsReportFac', data)
    },
  }
</script>
